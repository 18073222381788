@import '../sass-vars/coach-app.scss';

// SMALL SCREEEN (MOBILE)
@page {
    size: A4;
    margin: 10px 5px;
}

.print-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    box-shadow: inset 0px 0px 8px #272727aa;
    width: 21.5cm;
    margin: 10px auto;
    padding: .5cm;

}

.print-page {
    width: 100%;
    height: 100%;
    padding: 20px 5px;
    overflow-y: auto;
}

.print-nav-stuff {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
}

.print-head-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-height: 1200px;
    border-bottom: $lightBorder;
}

@media print {
    .print-head-cont {
        border-bottom: none;
        min-height: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

.print-program {
    position: relative;
    width: 100%;
    min-height: 100%;
    background: white;
    padding: 10px 30px;

    .print-program-head {
        position: relative;

        display: grid;
        height: 195px;
        grid-template-columns: 33.33% 33.34% 33.33%;
        align-content: center;
        grid-template-rows: 65px 65px 65px;
        background: white;
        border-radius: 4px;
        border: 1px solid #ccc;
        margin: 50px 15px 3px 15px;
        text-align: center;

        h1 {
            color: $soliddark;
        }

        h4 {
            margin-bottom: 3px;
        }

        p {
            margin-top: 0px;
        }

        .variable {
            text-align: center;
            display: flex;
            width: 30%;
            flex-direction: column;
            margin: 5px;

            input {
                text-align: center;
                border: 1px solid #bbb;
                border-radius: 2px;
                box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
                background: #eeeeee;
            }

            select {
                text-align: center;
                border: 1px solid #bbb;
                border-radius: 2px;
                height: 26px;
                box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
                background: #eeeeee;
            }
        }
    }

    .print-program-config {

        display: flex;
        justify-content: space-between;
        overflow: auto;
        margin: 10px 10px;

        .config-item {

            width: 14.25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            padding: 0 5px;

            .config-item-title {
                width: 100%;
                height: 50px;
                text-align: center;
                border-radius: 6px;
                color: #eee;
                background-color: $soliddark;
                font-weight: 500;
                font-size: small;
                line-height: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .print-config-pill {
                width: 100%;
                height: 50px;
                text-align: center;
                border-radius: 6px;
                font-weight: bold;
                font-size: small;
                color: white;
                background-color: $solidmid;
                line-height: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }


        }
    }
}