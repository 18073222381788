@import '../sass-vars/coach-app.scss';


  .controls{
    display: flex;
    gap: 30px;
  }
  .warmup-cooldown {
    width: calc(100% - 20px);
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    .warmup-cooldown-item{
      width: 100%;
      background-color: white;
      border-radius: $borderradius;
      border: $lightBorder;
      box-shadow: $box-shadow;
    }
    .text-edit {
      width: calc(100% - 30px)
    }
    .showWUCD {
      display: none;
    }
  }
  


@media (max-width: $large) {
  .controls{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .warmup-cooldown {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap:10px;
    .warmup-cooldown-item{
      width: 100%;
      background-color: white;
      border-radius: 6px;
      box-shadow: $box-shadow;
      border: $lightBorder;
    }
    .text-edit {
      width: calc(100% - 30px)
    }
    .showWUCD {
      display: none;
    }
  }
}
.workout {
  width: 100%;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  
}

