@import "../sass-vars/coach-app.scss";

.schedule-cont {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.calendar-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 5px;
  
}

.calendar {
  width: 100%;
  height: calc(100% - 30px);
  --fc-today-bg-color: #A4DFD744;
  .fc-col-header-cell-cushion{
    font-size: .8em;
  }  
}
