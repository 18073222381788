@import '../../style/sass-vars/coach-app.scss';
@media(max-width: $small) {
  .client-card {
    position: relative;
    width: calc(100% - 20px);
  }
}

// SMALL SCREEEN (MOBILE)
@media(min-width: $small) {
  .client-card {
    position: relative;
    width: calc(100% - 20px);
  }
}

.client-card {
  background-color: white; 
  border-radius: 6px;
  box-shadow: $box-shadow;
  .cardComment {
    margin: 5px 2.5px;
    width: 100%;
    resize: none;
    border-color: $lightBorder;
  }
  .cardHead {
    position: relative;
    width: 100%;
    align-items: center;
    padding-top: 5px;
    display: grid;
    grid-template-columns: 60px 1fr ;
  
    .cardNo {
      position: relative;
      background-color: $solidmid;
      text-align: center;
      border-radius: 5px;
      border: none;
      height: 45px;
      width: 45px;
      color: white;
      font-size: 1.8em;
      font-weight: bold;
    }
    .head_item {
      display: flex;
      justify-content: center;
      
    }
    .showCard {
      position: relative;
      text-align: center;
      background: $solidmid;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      margin: 5px;
      color: white;
      border: none;
      cursor: pointer;
    }
    .exitbutton {
      position: relative;
      text-align: center;
      background: $solidmid;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      margin: 5px;
      color: white;
      border: none;
      cursor: pointer;
    }
  }
  
  .cardBody {
    position: relative;
    padding: 3px;
    width: 100%;
    
  }
  .variation-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 5px 10px;
    margin-top: 5px;
  }
  .variation {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 1rem;
    flex: 1;
    min-width: 165px;
    max-width: 180px;
    min-height: 28px;
  }
  .variation p {
    flex: 1; 
    text-align: center;
    font-size: .8rem;
    color: #666666;
    line-height: 1rem;
    min-width: 65px;
  }
  .variation span {
    font-size: .8rem;
    line-height: 1rem;
  }
  
}

