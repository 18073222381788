@import '../sass-vars/coach-app.scss';

@media print {
  .print-card {
    display: block;
   
    page-break-inside: avoid; 
  }  
}
.print-card {
  display: block;
  page-break-inside: avoid;    
  position: relative;
  width: 100% ;
    border-radius: 6px;
    border: $lightBorder;
  
    .cardComment {
      margin: 5px;
      width: calc(100% - 10px);
      resize: none;
      border-color: $lightBorder;
    }
    .cardHead {
      position: relative;
      width: 100%;
      align-items: center;
      padding-top: 5px;
      display: grid;
      grid-template-columns: 60px 1fr 60px ;
    
      .card-no {
        position: relative;
        background-color: $solidmid;
        text-align: center;
        border-radius: 5px;
        border: none;
        height: 40px;
        width: 45px;
        color: white;
        font-size: 1.8em;
        font-weight: bold;
        line-height: 40px;
      }
      .head_item {
        display: flex;
        justify-content: center;
        
      }
      .showCard {
        position: relative;
        text-align: center;
        background: $solidmid;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        margin: 5px;
        color: white;
        border: none;
        cursor: pointer;
      }
      .exitbutton {
        position: relative;
        text-align: center;
        background: $solidmid;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        margin: 5px;
        color: white;
        border: none;
        cursor: pointer;
      }
    }
    .variation-cont{
      padding: 0px 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
    }
    
      .variation{
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 33.3%;
        min-width: 120px;
        text-align: center;
      }
    
    .cardBody {
      position: relative;
      padding: 3px;
      width: 100%;
      
    }
    .cardTracking {
      border-top: 0.25px solid #666666;
      display: flex;
      justify-content: space-evenly;
      padding: 2.5px;
      flex-wrap: wrap;
      align-items: center;
      .variable {
        text-align: center;
        display: flex;
        label {
          padding-right: 5px;
        }
        input {
          border: 1px solid #bbb;
          border-radius: 2px;
          box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
          background: #eeeeee;
          width: 150px;
        }
      }
    }
  }

