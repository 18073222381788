@import '../sass-vars/coach-app.scss';

@media(max-width: $small) {
  .exercise_card{
    position: relative;
    width: calc(100% - 10px);
  }
}
// SMALL SCREEEN (MOBILE)
@media(min-width: $small) {
  .exercise_card{
    position: relative;
    width: calc(100% - 20px);
  }

}

.exercise_card{
  background-color: white;
    
    border-radius: $borderradius;
    box-shadow: $box-shadow;
    border: $lightBorder;
    .cardComment {
      margin: 5px;
      width: calc(100% - 10px);
      resize: none;
      border-color: $lightBorder;
    }
    .cardHead {
      position: relative;
      width: 100%;
      align-items: center;
      display: grid;
      grid-template-columns: 60px 1fr 60px;
      height: 60px;
      .cardNo {
        position: relative;
        background-color: $solidmid;
        text-align: center;
        border-radius: 5px;
        border: none;
        height: 42px;
        max-width: 42px;
        color: white;
        font-size: 1.8em;
        font-weight: bold;
      }
      .head_item {
        display: flex;
        justify-content: center;
      }
      .showCard {
        position: relative;
        text-align: center;
        background: $solidmid;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        margin: 5px;
        color: white;
        border: none;
        cursor: pointer;
      }
      .exitbutton {
        position: relative;
        text-align: center;
        background: $solidmid;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        margin: 5px;
        color: white;
        border: none;
        cursor: pointer;
      }
    }
    .coach-variation-cont{
      padding: 0px 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
    }
    
      .coach-variation{
        display: flex;
        justify-content: center;
        height: 45px;
        min-width: 120px;
        margin-top: 6px;
        width: 15%;
        align-items: center;
      }
    
      .variable {
        width: 95%; 
        margin-left: 2.5%;
        margin-right: 2.5%;
        
        
        
      }
    
    .cardBody {
      position: relative;
      padding: 3px;
      width: 100%;
      
    }
    .cardTracking {
      border-top: 0.25px solid #666666;
      display: flex;
      justify-content: space-evenly;
      padding: 2.5px;
      flex-wrap: wrap;
      align-items: center;
      .variable {
        text-align: center;
        display: flex;
        label {
          padding-right: 5px;
        }
        input {
          border: 1px solid #bbb;
          border-radius: 2px;
          box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
          background: #eeeeee;
          width: 150px;
        }
      }
    }
  }

