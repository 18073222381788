@import './sass-vars/coach-app.scss';

.layoutMainCoach {
  display: grid;
  background-color: $background_dark;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  grid-template-columns: minmax(0px, 170px) 1fr;
  grid-template-rows: 100%;
  padding: 7.5px;

  .main-head {
    background-color: white;
    box-shadow: $box-shadow;
    z-index: 1;
    display: grid;
    align-items: center;
    text-align: center;
    grid-template-columns: 40px 1fr 55px;
    border-bottom: $lightBorder;
    border-radius: 4px 4px 0px 0px;
  }

  .layoutHeaderCoach {
    grid-column: 1/ 3;
    grid-row: 1/2;
    z-index: 1200;
    display: block;
    height: 45px;
    width: 100%;
    position: fixed;
  }

  .layoutSidebarCoach {
    grid-column: 1 / 2;
    height: 100%;
    z-index: 5;
    background-color: none;
  }

  aside {
    display: block;
  }

  main {
    display: grid;
    grid-template-rows: 45px 1fr;
    grid-column: 2/3;
    flex: 1;
    background-color: $background;
    border-radius: $borderradius;
    transition: 500ms;
    border: solid 1px grey;

    .main {

      overflow-y: auto;
    }
  }
}

@media(max-width: $small) {
  .layoutMainCoach {
    padding: 0px;
  }
}

@media(max-width: $medium) {
  .layoutMainCoach {
    grid-template-columns: minmax(0px, 55px) 1fr;

    main {
      border: none;
      border-radius: 0px;
    }
    .main-head {
      border-radius:0px;
    }
  }
}

// LAYOUT CLIENT
.layoutMainClient {
  display: grid;
  height: 100vh;
  grid-template-rows: 55px 1fr;
  grid-template-columns: 100%;
  overflow-y: hidden;

  .layoutHeaderClient {
    top: 0;
    z-index: 2;
    grid-row: 1/2;
  }

  header {
    display: block;
  }

  .layoutBodyClient {
    grid-row: 2/3;
    position: relative;
  }

  main {
    display: block;
    background-color: $background;
    overflow-y: auto;
  }
}

.lds-ellipsis div {
  background-color: $soliddark !important;
}