@import '../../style/sass-vars/coach-app.scss';
// SMALL SCREEEN (MOBILE)
@media(max-width: $small) {
    // Workout
    .workout {
        width:95vw;
        .warmupCooldown {
            width: 95vw;
            margin-bottom:5px;
            margin-left: auto;
            margin-right: auto;
            display:flex;
            flex-direction: column;
            .showWUCD {
                margin-left: 30px;
                margin-right:30px;
                height: 35px;
            }
            .variable {
                text-align: center;
                display:flex;
                flex-direction: column;
                display:none;
                .textarea {
                    border:1px solid #bbb;
                    border-radius:2px;
                    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
                    box-shadow:  0px 2px 4px rgba(0, 0, 0, 0.25);
                    background: white;
                    min-height: 150px;
                    width: 95vw;
                    resize: none;
                    padding: 10px;
                }
            } 
        }
        
    }
    .text-edit-client {
        .ql-container.ql-snow {
            border: none;
        }
        .ql-toolbar {
            display:none;
        }
    }
    
}

// DESKTOP
@media(min-width: $small) {
    // Workout
    .workout {
        width:100%;
        .warmupCooldown {
            width: 100%;
            display:flex;
            justify-content:space-between;
            margin-bottom:5px;
            margin-left: auto;
            margin-right: auto;
            .showWUCD{
                display: none;
            }
            .variable {
                text-align: center;
                display:flex;
                flex-direction: column;
                .textarea {
                    border:1px solid #bbb;
                    border-radius:2px;
                    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
                    box-shadow:  0px 2px 4px rgba(0, 0, 0, 0.25);
                    background: white;
                    min-height: 150px;
                    width: 420px;
                    resize: none;
                    padding: 10px;
                }
            } 
        } 
    }
    .text-edit-client {
        .ql-container.ql-snow {
            border: none;
        }
        .ql-toolbar {
            display:none;
        }
    }
}